import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/style.css'
import 'flowbite/dist/flowbite'
import 'aos/dist/aos'
import 'aos/dist/aos.css'
import { library } from "@fortawesome/fontawesome-svg-core";
import { 
    faPhone,
    faCode,
    faScrewdriverWrench,
    faWandMagicSparkles,
    faMobileScreenButton,
    faChartSimple,
    faServer,
    faEnvelope,
    faGlobe,
    faChalkboardTeacher,
} from "@fortawesome/free-solid-svg-icons";
import { 
        faFacebookF,
        faWhatsapp,
        faInstagram,
        faXTwitter,
        faThreads,
        faTelegram,
        faGithub,
        faSnapchat,
    } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
    faPhone,
    faCode,
    faScrewdriverWrench,
    faWandMagicSparkles,
    faMobileScreenButton,
    faChartSimple,
    faServer,
    faEnvelope,
    faGlobe,
    faChalkboardTeacher,
    faFacebookF,
    faWhatsapp,
    faInstagram,
    faXTwitter,
    faThreads,
    faTelegram,
    faGithub,
    faSnapchat,
);

createApp(App)
    .component("FontAwesomeIcon", FontAwesomeIcon)
    .use(store)
    .use(router)
    .mount("#app");