<template>
  <div>
    <section class="py-10 md:py-16">

<div class="container max-w-screen-xl mx-auto px-4">

    <nav class="flex items-center justify-between mb-40">
        <img src="../assets/logos/eshaibani-logo-black.png" class="h-12" alt="Logo">

        <button class="px-7 py-3 md:px-9 md:py-4 bg-white font-medium md:font-semibold text-gray-700 text-md rounded-md hover:bg-gray-700 hover:text-white transition ease-linear duration-500">Get my CV</button>
    </nav>

    <div class="text-center">
        <div class="flex justify-center mb-16" data-aos="fade-in">
            <img src="https://avatars.githubusercontent.com/u/87685172?v=4" class="h-60 max-w-full rounded-full hover:scale-110 translation duration-100 object-cover overflow-hidden" alt="Ebrahim Image">
        </div>

        <h6 class="font-medium text-gray-600 text-lg md:text-2xl uppercase mb-8" data-aos="fade-in" data-aos-delay="800">
            Ebrahim Hasan
        </h6>

        <h1 class="font-normal text-gray-900 text-4xl md:text-7xl leading-none mb-8"
            data-aos="fade-in" data-aos-delay="900">
            Full Stack Developer
        </h1>

        <p class="font-normal text-gray-600 text-md md:text-xl mb-16"
            data-aos="fade-in" data-aos-delay="1000">
            I have a passion for software. I enjoy creating tools that make life easier for people.
        </p>

        <a 
            href="#contact" 
            class="px-7 py-3 md:px-9 md:py-4 font-medium md:font-semibold bg-gray-700 text-gray-50 text-sm rounded-md hover:bg-gray-50 hover:text-gray-700 transition ease-linear duration-500"
            data-aos="fade-in" data-aos-delay="1000">
            Contact me</a>
    </div>

</div>

</section>

<section class="py-10 md:py-16">

<div class="container max-w-screen-xl mx-auto px-4">
    <div class="mb-10 lg:mb-0">
            <h1 class="font-medium text-gray-700 text-3xl md:text-4xl mb-5">My Skills</h1>
            <p class="font-normal text-gray-500 text-xs md:text-base">Take a look at my diverse set of skills and expertise.</p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div v-for="(skill, index) in skills" :key="index" class="bg-gray-50 px-8 py-10 rounded-md" data-aos="fade-in">
            <div class="w-20 py-6 flex justify-center bg-gray-100 rounded-md mb-4">
                <font-awesome-icon :icon="skill.icon" size="md" />
            </div>
            <h4 class="font-medium text-gray-700 text-lg mb-4">{{ skill.title }}</h4>
            <p class="font-normal text-gray-500 text-md">{{ skill.description }}</p>
        </div>
    </div>
</div>
</section>

<section class="py-10 md:py-16">

<div class="container max-w-screen-xl mx-auto px-4">

    <div class="flex flex-col lg:flex-row justify-between">
        <div class="mb-10 lg:mb-0 mx-4">
            <h1 class="font-medium text-gray-700 text-3xl md:text-4xl mb-5">Projects</h1>

            <p class="font-normal text-gray-500 text-xs md:text-base">I have brought here my biggest and favorite works <br> as a professional.</p>
        </div>

        <div class="space-y-24">
        
        

            <div class="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-8">
                <div v-for="(project, index) in projects" :key="index" class="h-auto max-w-full" data-aos="fade-out">
                    <img class="h-72 w-full object-cover rounded-lg" :src="project.image" :alt="project.title" loading="lazy">
                    <a v-if="project.url != ''" :href="project.url" target="_blank"><h1 class="text-gray-800 dark:text-slate-200 font-semibold text-lg mx-2">{{ project.title }}</h1></a>
                    <h1 v-else class="text-gray-800 dark:text-slate-200 font-semibold text-lg mx-2">{{ project.title }}</h1>
                    <p class="text-gray-700 dark:text-slate-300 text-sm mx-2 text-start">{{ project.description }}</p>
                </div>
            </div>

        
        </div>
    </div>

</div>

</section>

<section class="py-10 md:py-16">

<div class="container max-w-screen-xl mx-auto px-4">

    <h1 class="font-medium text-gray-700 text-3xl md:text-4xl mb-5">Education</h1>

    <p class="font-normal text-gray-500 text-xs md:text-base mb-20">Below is a summary of the places I studied</p>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">

        <div class="bg-gray-50 px-8 py-10 rounded-md"
                v-for="(study, index) in studies" :key="index"
                data-aos="fade-in">
            <h4 class="font-medium text-gray-700 text-lg mb-4">{{ study.period }}</h4>

            <p class="font-normal text-gray-500 text-md mb-4">{{ study.description }}</p>

            <div v-if="study.link" class="relative">
                <h6 class="font-semibold text-gray-500 text-md relative z-10">See the place here</h6>
                <span class="w-32 h-1 bg-blue-200 absolute bottom-1 left-0 z-0"></span>
            </div>
        </div>

    </div>

</div>

</section>


<!-- <section class="py-10 md:py-16">

<div class="container max-w-screen-xl mx-auto px-4">

    <h1 class="font-medium text-gray-700 text-3xl md:text-4xl mb-5">Experience</h1>

    <p class="font-normal text-gray-500 text-xs md:text-base mb-20">Below is a summary of the places I studied</p>

    <div class="flex flex-col lg:flex-row justify-between">
        <div class="space-y-8 md:space-y-16 mb-16 md:mb-0">
            <h6 class="font-medium text-gray-400 text-base uppercase">Company</h6>

            <p class="font-semibold text-gray-600 text-base">Massa Fames <span class="font-normal text-gray-300">/ New York</span></p>

            <p class="font-semibold text-gray-600 text-base">Massa Fames <span class="font-normal text-gray-300">/ New York</span></p>

            <p class="font-semibold text-gray-600 text-base">Massa Fames <span class="font-normal text-gray-300">/ New York</span></p>

            <p class="font-semibold text-gray-600 text-base">Massa Fames <span class="font-normal text-gray-300">/ New York</span></p>

            <p class="font-semibold text-gray-600 text-base">Massa Fames <span class="font-normal text-gray-300">/ New York</span></p>
        </div>

        <div class="space-y-8 md:space-y-16 mb-16 md:mb-0">
            <h6 class="font-medium text-gray-400 text-base uppercase">Position</h6>

            <p class="font-normal text-gray-400 text-base">Junior Front-End Developer</p>

            <p class="font-normal text-gray-400 text-base">Junior Front-End Developer</p>

            <p class="font-normal text-gray-400 text-base">Junior Front-End Developer</p>

            <p class="font-normal text-gray-400 text-base">Junior Front-End Developer</p>

            <p class="font-normal text-gray-400 text-base">Junior Front-End Developer</p>
        </div>

        <div class="space-y-8 md:space-y-16">
            <h6 class="font-medium text-gray-400 text-base uppercase">Year</h6>

            <p class="font-normal text-gray-400 text-base">2016</p>

            <p class="font-normal text-gray-400 text-base">2016</p>

            <p class="font-normal text-gray-400 text-base">2016</p>

            <p class="font-normal text-gray-400 text-base">2016</p>

            <p class="font-normal text-gray-400 text-base">2016</p>
        </div>
    </div>
    
</div>

</section> -->


<footer class="py-10 md:py-16 mb-20 md:mb-40 lg::mb-52">

<div class="container max-w-screen-xl mx-auto px-4" id="contact">

    <div class="text-center">
        <h1 class="font-medium text-gray-700 text-4xl md:text-5xl mb-5" data-aos="fade-in">Contact Me</h1>
        <p class="font-normal text-gray-400 text-md md:text-lg mb-20" data-aos="fade-in">Feel free to contact me for any <br> inquiries.</p>
        <div class="flex items-center justify-center space-x-8" data-aos="fade-in">
            <a href="https://www.facebook.com/e.h.shiabnai/" class="w-16 h-16 flex items-center justify-center rounded-full hover:bg-gray-200 transition ease-in-out duration-500">
                <i class="text-gray-500 hover:text-gray-800 transition ease-in-out duration-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                    </svg>
                </i>
            </a>
            <a href="https://api.whatsapp.com/send?phone=967777848782&text=Hello%20Ebrahim" class="w-16 h-16 flex items-center justify-center rounded-full hover:bg-gray-200 transition ease-in-out duration-500">
                <i class="text-gray-500 hover:text-gray-800 transition ease-in-out duration-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
                    </svg>
                </i>
            </a>
            <a href="https://www.instagram.com/ebrahim_.hasan/" class="w-16 h-16 flex items-center justify-center rounded-full hover:bg-gray-200 transition ease-in-out duration-500">
                <i class="text-gray-500 hover:text-gray-800 transition ease-in-out duration-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                    </svg>
                </i>
            </a>
            <a href="tel:967777848782" class="w-16 h-16 flex items-center justify-center rounded-full hover:bg-gray-200 transition ease-in-out duration-500">
                <i class="text-gray-500 hover:text-gray-800 transition ease-in-out duration-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                    </svg>
                </i>
            </a>
            <a href="https://github.com/EbrahimAlshaibani" class="w-16 h-16 flex items-center justify-center rounded-full hover:bg-gray-200 transition ease-in-out duration-500">
                <i class="text-gray-500 hover:text-gray-800 transition ease-in-out duration-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
                        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8"/>
                    </svg>
                </i>
            </a>
        </div>
    </div>
</div>
</footer>
</div>
</template>

<script>
import { ref } from 'vue';
import AOS from 'aos'
export default {
    name: 'HomeView',
    components: {
        
    },
    setup(){
        AOS.init();
        const skills =  ref([
            {
                "id":1,
                "title":"Front End Developer",
                "description":"I build client-side of web applications and user-friendly websites.",
                "icon":"code"
            },
            {
                "id":2,
                "title":"Back End Developer",
                "description":"I create server-side application including APIs and web applications.",
                "icon":"screwdriver-wrench"
            },
            {
                "id":3,
                "title":"UI/UX Designer",
                "description":"I design the user experience for digital products. to create an easy-to-use interface.",
                "icon":"wand-magic-sparkles"
            },
            {
                "id":4,
                "title":"Hybrid Apps",
                "description":"I create mobile applications using hybrid frameworks like Flutter.",
                "icon":"mobile-screen-button"
            },
            {
                "id":5,
                "title":"System Analysis",
                "description":"I specialize in system analysis, optimizing processes and enhancing functionality to drive business success.",
                "icon":"chart-simple"
            },
            {
                "id":6,
                "title":"Server Management",
                "description":"I excel in server management, ensuring optimal performance, security, and reliability for digital infrastructure.",
                "icon":"server"
            },
            {
                "id":7,
                "title":"Email Service",
                "description":"I specialize in email service management, delivering efficient and reliable email solutions tailored to meet diverse communication needs.",
                "icon":"envelope"
            },
            {
                "id":8,
                "title":"Domain Names Configuration",
                "description":"I specialize in configuring domain names, ensuring seamless navigation and optimal functionality for websites and online services.",
                "icon":"globe"
            },
            {
                "id":9,
                "title":"Teaching & Training Programming",
                "description":"I have expertise in instructing programming, guiding students to grasp coding principles, enhance problem-solving abilities, and adopt best practices in software development.",
                "icon":"chalkboard-teacher"
            },
        ])
        const projects = ref([
            {
                "title":"Tammem Travel",
                "description":"Tammem Travel Website and Email Service with official template for documents",
                "url":"https://tameemtravel.com",
                "image":require('../assets/projects/tameem.png')
            },
            {
                "title":"Together Marketing and Advertising",
                "description":"Together Marketing Website and Email Service",
                "url":"https://2gether-adv.com",
                "image":require('../assets/projects/togads.png')
            },
            {
                "title":"Yemen Club for Touring and Automobile",
                "description":"Yemen Club Website with news admin",
                "url":"https://yemenclubta.com",
                "image":require('../assets/projects/ycta.png')
            },
            {
                "title":"Fazaa Production",
                "description":"Fazaa Production Website and Email Service with admin panel",
                "url":"https://fazaa.sa",
                "image":require('../assets/projects/fazaa.png')
            },
            {
                "title":"GBG Logistics",
                "description":"GBG Logistics Website and Email Service",
                "url":"https://gbg.business",
                "image":require('../assets/projects/gbg.png')
            },
            {
                "title":"GDSC LIU Sana'a",
                "description":"GDSC Leader for the year 2022 -2023 ,Google Developer Students Club Website with admin panel",
                "url":"https://gdscliu.com",
                "image":require('../assets/projects/gdsc-logo.png')
            },
            {
                "title":"Programming Touter",
                "description":"Teaching C , JAVA , Android Applications Development, Web , Firebase and Google sites",
                "url":"http://www.liuyemen.com/",
                "image":require('../assets/projects/liu-sanaa.jpg')
            },
            {
                "title":"Pro Gamer Gaming Center",
                "description":"Developing Local Server for Movies and Games as websites like Netflix",
                "url":"",
                "image":require('../assets/projects/pg.png')
            },
            {
                "title":"Ragheb Travel",
                "description":"Domain Name and Email Service",
                "url":"https://ragheb.travel ",
                "image":require('../assets/projects/ragheb.jpg')
            },
            {
                "title":"Smart Time for smart electrics",
                "description":"Website, Email Service , and admin panel",
                "url":"https://smartime.ai",
                "image":require('../assets/projects/smartime.png')
            },
            {
                "title":"Together Information Technologies ERP Solutions",
                "description":"Website, Email Service",
                "url":"https://togits.com",
                "image":require('../assets/projects/togits.png')
            },
            {
                "title":"WM Real Estate",
                "description":"Website, Email Service",
                "url":"https://wmrealestates.com",
                "image":require('../assets/projects/wm.png')
            },
            {
                "title":"YARE.CO",
                "description":"Domain Name and Email Service",
                "url":"https://yaer.co",
                "image":require('../assets/projects/yaer.png')
            },
            {
                "title":"Zakaria Almareh Exchange",
                "description":"Domain ,Email Service , and official templates",
                "url":"https://smartime.ai",
                "image":require('../assets/projects/zakaria.jpg')
            },
            {
                "title":"Nasa Net",
                "description":"Networking service and website",
                "url":"",
                "image":require('../assets/projects/nasanet.jpg')
            },
            {
                "title":"Nahil Electromechanical Contracting",
                "description":"Domain ,Email Service",
                "url":"https://nahil.sa.com",
                "image":require('../assets/projects/nahil.png')
            },
            {
                "title":"Talia LTD",
                "description":"Full Stack website with control panel",
                "url":"https://talialtd.com",
                "image":require('../assets/projects/talia.png')
            },
            {
                "title":"Orange Travel",
                "description":"Website and Social Media Accounts",
                "url":"https://orangetravel.co",
                "image":require('../assets/projects/orange.png')
            },
            {
                "title":"Al-Dhafeeri Emar",
                "description":"Emails and website with control panel",
                "url":"https://dhafeeriemar.com",
                "image":require('../assets/projects/dhafeeriemar.png')
            },
            {
                "title":"BIM Masters Sana'a",
                "description":"Teaching Full Stack Web Developemnt for 6 months Django ,Laravel",
                "url":"https://www.bimmasters.net",
                "image":require('../assets/projects/bim.jpg')
            },
        ])
        const studies = ref([
            {
                "period":"2020-2023",
                "description":"bachelor of information technology & computer science at lebanese international university sana'a GPA 3.96",
                "link":"http://www.liuyemen.com",
            },
            {
                "period":"2021-2022",
                "description":"cisco certified network associate & networking ccna",
                "link":null,
            },
            {
                "period":"2020-2023",
                "description":"German Language in Das Deutsche Haus für Zusammenarbeit und Kultur Sana'a",
                "link":null,
            },
            {
                "period":"2018-2020",
                "description":"English Language at Exceed Language Center",
                "link":"https://www.exceededucation.org",
            },
        ])
        return {
            skills,
            projects,
            studies,
        }
    }
}
</script>
